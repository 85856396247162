import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "box relative" }
const _hoisted_2 = { class: "flex justify-end" }
const _hoisted_3 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_input_number = _resolveComponent("el-input-number")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["Form"], {
      as: "el-form",
      onSubmit: $setup.onSubmit,
      "label-width": "150px"
    }, {
      default: _withCtx(({ isSubmitting }) => [
        _createElementVNode("div", {
          class: _normalizeClass(['2xl:space-y-6 my-3', isSubmitting && 'isSubmitting-rounded'])
        }, [
          _cache[0] || (_cache[0] = _createElementVNode("h1", { class: "text-left text-lg 2xl:text-xl font-bold" }, " There are currently no available RECs fulfil your selected target. Would you like to create a buy order? ", -1)),
          _createVNode($setup["Field"], { name: "FuelType" }, {
            default: _withCtx(({ value, field, errorMessage }) => [
              _createVNode(_component_el_form_item, {
                class: "inputfield inputfield__underline inline",
                error: errorMessage,
                label: "Fuel Type"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_select, _mergeProps({ class: "w-56" }, field, {
                    "model-value": value,
                    placeholder: ""
                  }), {
                    default: _withCtx(() => [
                      (_openBlock(), _createElementBlock(_Fragment, null, _renderList([{ Id: 1, Name: '123123' }], (item) => {
                        return _createVNode(_component_el_option, {
                          key: item.Id,
                          label: item.Name,
                          value: item.Id
                        }, null, 8, ["label", "value"])
                      }), 64))
                    ]),
                    _: 2
                  }, 1040, ["model-value"])
                ]),
                _: 2
              }, 1032, ["error"])
            ]),
            _: 1
          }),
          _createVNode($setup["Field"], { name: "Country" }, {
            default: _withCtx(({ value, field, errorMessage }) => [
              _createVNode(_component_el_form_item, {
                class: "inputfield inputfield__underline inline",
                error: errorMessage,
                label: "Country"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_select, _mergeProps({ class: "w-56" }, field, {
                    "model-value": value,
                    placeholder: ""
                  }), {
                    default: _withCtx(() => [
                      (_openBlock(), _createElementBlock(_Fragment, null, _renderList([{ Id: 1, Name: '123123' }], (item) => {
                        return _createVNode(_component_el_option, {
                          key: item.Id,
                          label: item.Name,
                          value: item.Id
                        }, null, 8, ["label", "value"])
                      }), 64))
                    ]),
                    _: 2
                  }, 1040, ["model-value"])
                ]),
                _: 2
              }, 1032, ["error"])
            ]),
            _: 1
          }),
          _createVNode($setup["Field"], { name: "AssetName" }, {
            default: _withCtx(({ value, field, errorMessage }) => [
              _createVNode(_component_el_form_item, {
                class: "inputfield inputfield__underline inline",
                error: errorMessage,
                label: "Asset Name"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_select, _mergeProps({ class: "w-56" }, field, {
                    "model-value": value,
                    placeholder: ""
                  }), {
                    default: _withCtx(() => [
                      (_openBlock(), _createElementBlock(_Fragment, null, _renderList([{ Id: 1, Name: '123123' }], (item) => {
                        return _createVNode(_component_el_option, {
                          key: item.Id,
                          label: item.Name,
                          value: item.Id
                        }, null, 8, ["label", "value"])
                      }), 64))
                    ]),
                    _: 2
                  }, 1040, ["model-value"])
                ]),
                _: 2
              }, 1032, ["error"])
            ]),
            _: 1
          }),
          _createVNode($setup["Field"], { name: "VintageFrom" }, {
            default: _withCtx(({ value, field, errorMessage }) => [
              _createVNode(_component_el_form_item, {
                class: "inputfield inputfield__underline inline",
                error: errorMessage,
                label: "Vintage From"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_date_picker, _mergeProps(field, {
                    "model-value": value,
                    editable: false,
                    type: "month",
                    placeholder: ""
                  }), null, 16, ["model-value"])
                ]),
                _: 2
              }, 1032, ["error"])
            ]),
            _: 1
          }),
          _createVNode($setup["Field"], { name: "VintageTo" }, {
            default: _withCtx(({ value, field, errorMessage }) => [
              _createVNode(_component_el_form_item, {
                class: "inputfield inputfield__underline inline",
                error: errorMessage,
                label: "Vintage To"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_date_picker, _mergeProps(field, {
                    "model-value": value,
                    editable: false,
                    type: "month",
                    placeholder: ""
                  }), null, 16, ["model-value"])
                ]),
                _: 2
              }, 1032, ["error"])
            ]),
            _: 1
          }),
          _createVNode($setup["Field"], { name: "UnitPriceUSD" }, {
            default: _withCtx(({ value, field, errorMessage }) => [
              _createVNode(_component_el_form_item, {
                class: "inputfield inputfield__underline inline",
                error: errorMessage,
                label: "Unit Price USD"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input_number, _mergeProps({
                    controls: false,
                    class: "w-56",
                    placeholder: ""
                  }, field, { "model-value": value }), null, 16, ["model-value"])
                ]),
                _: 2
              }, 1032, ["error"])
            ]),
            _: 1
          }),
          _createVNode($setup["Field"], { name: "Quantity" }, {
            default: _withCtx(({ value, field, errorMessage }) => [
              _createVNode(_component_el_form_item, {
                class: "inputfield inputfield__underline inline",
                error: errorMessage,
                label: "Quantity"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input_number, _mergeProps({
                    controls: false,
                    class: "w-56",
                    placeholder: ""
                  }, field, { "model-value": value }), null, 16, ["model-value"])
                ]),
                _: 2
              }, 1032, ["error"])
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("button", {
              type: "submit",
              class: "btn btn--red-primary mt-5",
              disabled: isSubmitting
            }, " Submit ", 8, _hoisted_3)
          ])
        ], 2)
      ]),
      _: 1
    })
  ]))
}