import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref } from "vue"

const _hoisted_1 = {
  key: 0,
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_2 = ["xlink:href"]
const _hoisted_3 = ["src"]

import { computed, reactive } from "vue";

interface Props {
  path?: string;
  url?: string;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'BaseSvgImage',
  props: {
    path: {},
    url: {}
  },
  setup(__props: any) {

const props = __props;
const { path } = reactive(props);

const imagePath = computed(() => {
  if (path) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    let image = require(`@/assets/images/${path}`);

    if (Object.prototype.hasOwnProperty.call(image, "default")) {
      image = image.default;

      return image.url;
    }
  }

  return null;
});

return (_ctx: any,_cache: any) => {
  return (imagePath.value)
    ? (_openBlock(), _createElementBlock("svg", _hoisted_1, [
        _createElementVNode("use", {
          "xlink:href": imagePath.value,
          "xmlns:xlink": "http://www.w3.org/1999/xlink"
        }, null, 8, _hoisted_2)
      ]))
    : (_unref(path))
      ? (_openBlock(), _createElementBlock("img", {
          key: 1,
          class: "object-cover",
          src: require(`@/assets/images/${_unref(path)}`),
          alt: "none"
        }, null, 8, _hoisted_3))
      : _createCommentVNode("", true)
}
}

})