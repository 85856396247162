import { defineComponent as _defineComponent } from 'vue'
import { Field, Form } from "vee-validate";


export default /*@__PURE__*/_defineComponent({
  __name: 'BuyOrder.Form',
  emits: ["handleSuccess"],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const emits = __emit;

const onSubmit = async (values: any) => {
  emits("handleSuccess", true);
};

const __returned__ = { emits, onSubmit, get Field() { return Field }, get Form() { return Form } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})