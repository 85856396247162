import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "my-4 px-10 py-1" }
const _hoisted_2 = { class: "flex space-x-4 mb-3" }
const _hoisted_3 = { class: "font-bold" }
const _hoisted_4 = {
  key: 0,
  class: "grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 2xl:gap-5 gap-3 relative"
}
const _hoisted_5 = { class: "2xl:col-span-2" }
const _hoisted_6 = { class: "space-y-5 mt-2 lg:mt-0 lg:ml-5 2xl:ml-10" }
const _hoisted_7 = {
  key: 1,
  class: "box grid grid-cols-3 2xl:gap-5 2xl:py-5 py-3 mb-5"
}
const _hoisted_8 = { class: "col-span-2 2xl:ml-10" }
const _hoisted_9 = { class: "flex justify-center items-end h-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ol", _hoisted_2, [
      _createElementVNode("li", null, [
        _createVNode(_component_router_link, { to: "/" }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("Home")
          ])),
          _: 1
        })
      ]),
      _cache[4] || (_cache[4] = _createElementVNode("li", null, ">", -1)),
      _createElementVNode("li", null, [
        _createVNode(_component_router_link, { to: "/setting-information/your-green-options" }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode(" Your Green Options ")
          ])),
          _: 1
        })
      ]),
      _cache[5] || (_cache[5] = _createElementVNode("li", null, ">", -1)),
      _createElementVNode("li", null, [
        _createVNode(_component_router_link, { to: "/setting-information/your-green-options/select-standard" }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode(" Select Standard ")
          ])),
          _: 1
        })
      ]),
      _cache[6] || (_cache[6] = _createElementVNode("li", null, ">", -1)),
      _createElementVNode("li", _hoisted_3, [
        _createVNode(_component_router_link, { to: "buy-order" }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createTextVNode("Buy Order")
          ])),
          _: 1
        })
      ])
    ]),
    (!$setup.isSuccess)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode($setup["BuyOrderForm"], { onHandleSuccess: $setup.handleSuccess })
          ]),
          _createElementVNode("div", _hoisted_6, [
            _cache[7] || (_cache[7] = _createElementVNode("h1", { class: "mt-2 text-left text-lg 2xl:text-xl font-bold" }, " Scope 2 Renewables Performance ", -1)),
            _createVNode($setup["ColumnChart"], {
              class: _normalizeClass(_ctx.styles['column-size']),
              data: $setup.dataScope_2,
              colors: ['#ffeecc', '#ffeecc', '#5e5e5e'],
              data_2: $setup.dataRenewables_2,
              colors_2: ['#addfa4', '#addfa4', '#5e5e5e'],
              seriesName: "Scope 2 (kWh)",
              seriesName_2: "Renewables (kWh)",
              lineSeriesName: "Renewables (%)",
              lineData: $setup.dataTarget_2,
              lineMarker: $setup.dataMarker,
              lineMarkerName: "Target (%)",
              yTitle: "kWh",
              legendStyle: {
            position: 'bottom',
            fontSize: 12,
            padding: [15, 0, 0, 0],
          },
              withExtraYScale: true,
              lineAllowExtraYScale: true
            }, null, 8, ["class", "data", "data_2", "lineData", "lineMarker"])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _cache[8] || (_cache[8] = _createStaticVNode("<div class=\"flex flex-col justify-center items-center text-sm 2xl:text-lg\"><h1 class=\"text-left 2xl:text-xl font-bold\">Thank you!</h1><p class=\"mt-3 2xl:mt-6 whitespace-nowrap\"> We have received your buy order: </p><p class=\"mt-2 2xl:mt-5\">Vintage:</p><p class=\"font-semibold\">Year 20XX</p><p class=\"mt-2 2xl:mt-5\">Standard selected:</p><p class=\"font-semibold\">RE100 Standards</p><p class=\"mt-2 2xl:mt-5\">REC Quantity:</p><p class=\"font-semibold\">XXX MWh</p><p class=\"mt-2 2xl:mt-5\">Location / Facility:</p><p class=\"font-semibold\">Singapore / Facility 1</p></div>", 1)),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, (idx) => {
                return _createElementVNode("div", {
                  key: idx,
                  class: _normalizeClass(['relative flex items-end', idx > 1 && '-ml-2'])
                }, [
                  _createVNode($setup["BaseSvgImage"], {
                    class: _normalizeClass([_ctx.styles[`tree_${idx}`]]),
                    path: `tree_exp/step_${idx}_${
                $setup.step === idx ? 'green' : 'gray'
              }.svg`
                  }, null, 8, ["class", "path"]),
                  _createElementVNode("div", {
                    class: _normalizeClass([
                'w-full absolute border-4 rounded left-0 bottom-0',
                $setup.step === idx
                  ? _ctx.styles.tree_underline_green
                  : 'border-gray-500 -z-1',
              ])
                  }, null, 2)
                ], 2)
              }), 64))
            ])
          ])
        ]))
  ]))
}